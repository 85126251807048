import { useCallback, useEffect, useRef, useState } from "react";
import * as R from "ramda";
import cx from "classnames";
import { arrayOf, shape } from "prop-types";
import { imageBlockFields } from "../../common/contentful-extract.jsx";
import useMatchMedia from "../../common/useMatchMedia.js";

import "./image-slider.scss";

import BackgroundImage from "gatsby-background-image";

const propTypes = {
  imageBlocks: arrayOf(shape(imageBlockFields)).isRequired,
};

const getId = R.prop("title");

const INTERVAL = 10000; // ms

const ImageSlider = (props) => {
  const { imageBlocks } = props;

  const elementsRef = useRef({});

  const [isPlaying, setPlaying] = useState(true);

  const [active, setActiveIndex] = useState(getId(imageBlocks[0]));
  const prev = usePrevious(active);

  // put the previous image last (on top) so we can fade it out
  const blocksInRenderOrder = R.sort(
    R.ascend((block) => {
      return R.equals(getId(block), prev) ? 2 : 1;
    }),
  )(imageBlocks);

  const gotoNext = useCallback(() => {
    const nextIndex = R.compose(
      (i) => getId(imageBlocks[i]), // get id from imageblock
      (i) => (i + 1) % imageBlocks.length, // next with wrap
      R.findIndex((block) => getId(block) === active),
    )(imageBlocks);

    setActiveIndex(nextIndex);
  }, [active, imageBlocks]);

  useEffect(() => {
    if (isPlaying) {
      const intervalId = setInterval(gotoNext, INTERVAL);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [gotoNext, isPlaying]);

  // we have to update faded-out class _after_ the reorder render
  // (instead of just rendering the faded-out class with cx)
  useEffect(() => {
    window.requestAnimationFrame(() => {
      R.mapObjIndexed((el, id) => {
        el.classList.toggle(
          "image-slider__image-holder--faded-out",
          active !== id,
        );
      })(elementsRef.current);
    });
  }, [active, blocksInRenderOrder]);

  // stop playback on mobile
  const isDesktop = useMatchMedia("(min-width: 746px)"); // should match $bp-medium-min
  useEffect(() => {
    setPlaying(isDesktop);
  }, [isDesktop]);

  return (
    <div className="image-slider">
      <div className="image-slider__image-area">
        <div className="image-slider__images">
          {blocksInRenderOrder.map((block) => {
            return (
              <div
                key={getId(block)}
                ref={(el) => (elementsRef.current[getId(block)] = el)}
                className="image-slider__image-holder"
              >
                <BackgroundImage fluid={block.fluidImage} alt={block.title} />
              </div>
            );
          })}
        </div>

        <div className="image-slider__play-pause">
          <button
            type="button"
            onClick={(e) => {
              setPlaying((p) => !p);

              // start on the next
              if (!isPlaying) {
                gotoNext();
              }
            }}
            title={isPlaying ? "Pause image slideshow" : "Play image slideshow"}
          >
            {isPlaying ? pauseSvg : playSvg}
          </button>
        </div>
      </div>

      <div className="image-slider__blocks">
        {imageBlocks.map((imageBlock) => {
          const isActive = getId(imageBlock) === active;

          return (
            <div
              key={imageBlock.title}
              className={cx("image-slider__block", {
                "image-slider__block--is-active": isActive,
                "image-slider__block--is-playing": isActive && isPlaying,
              })}
            >
              {isActive && isPlaying && (
                <div
                  className="image-slider__block-timer"
                  style={{ animationDuration: `${INTERVAL}ms` }}
                />
              )}
              <button
                type="button"
                onClick={(e) => {
                  setActiveIndex(getId(imageBlock));
                  setPlaying(false);
                }}
              >
                {imageBlock.title}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

ImageSlider.propTypes = propTypes;
export default ImageSlider;

function usePrevious(value) {
  const [current, setCurrent] = useState(value);
  const [prev, setPrev] = useState();

  if (!R.equals(value, current)) {
    setPrev(current);
    setCurrent(value);
  }

  return prev;
}

const pauseSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    stroke="currentColor"
    strokeWidth="0"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-pause"
  >
    <rect x="6" y="4" width="4" height="16"></rect>
    <rect x="14" y="4" width="4" height="16"></rect>
  </svg>
);

const playSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    stroke="currentColor"
    strokeWidth="0"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-play"
  >
    <polygon points="5 3 19 12 5 21 5 3"></polygon>
  </svg>
);
