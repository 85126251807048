import { useEffect, useRef } from "react";
import * as R from "ramda";
import { useStaticQuery, graphql, Link } from "gatsby";
import { scrollToElement } from "../../common/scrollHelpers.js";

import "./home-page.scss";

import "aos/dist/aos.css";
import AOS from "aos";

import {
  extractBanner,
  extractImageBlocks,
  extractLink,
  extractRichText,
} from "../../common/contentful-extract.jsx";

import Banner from "../Banner/Banner.jsx";
import Container100 from "../Container/Container100.jsx";
import ImageBlock from "../ImageBlock/ImageBlock.jsx";
import ImageSlider from "../ImageSlider/ImageSlider.jsx";
import Page from "../Page/Page.jsx";
import Search from "../Search/Search.jsx";
import Section from "../Section/Section.jsx";

const HomePage = () => {
  const contentfulData = useStaticQuery(query);

  const contentRef = useRef();

  const data = R.compose(
    R.evolve({
      bottomBanner: extractBanner,
      campaignGoalsSectionTitle: extractRichText,
      campaignGoalsSectionSubtitle: extractRichText,
      campaignGoalsLink: extractLink,
      campaignGoalsImageBlocks: extractImageBlocks,
      campaignPillarsImageBlocks: extractImageBlocks,
      campaignPillarsSectionTitle: extractRichText,
      campaignPillarsLink: extractLink,
      campaignPillarsSectionSubtitle: extractRichText,
      topHeroBanner: extractBanner,
    }),
    R.defaultTo({}),
    R.prop("contentfulHomePage"),
  )(contentfulData);

  const {
    bottomBanner,
    campaignGoalsSectionTitle,
    campaignGoalsSectionSubtitle,
    campaignGoalsImageBlocks,
    campaignGoalsLink,
    campaignPillarsImageBlocks,
    campaignPillarsSectionTitle,
    campaignPillarsLink,
    campaignPillarsSectionSubtitle,
    topHeroBanner,
  } = data;

  useEffect(() => {
    // https://github.com/michalsnik/aos
    // initialize animation on scroll when the page loads
    AOS.init({
      offset: 100,
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <Page topHeroBanner={topHeroBanner}>
      <Banner hero {...topHeroBanner}>
        <div className="home-page__hero">
          <Search />
          <br />
          <Link to="/map/US" className="btn btn--text">
            Or View the Progress Map{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="13"
              viewBox="0 0 21 13"
            >
              <g fill="currentColor">
                <path d="M.682 7.647V5.475l17.356.044v2.172z" />
                <path d="M18.133 8.14l-5.245-5.245 1.535-1.535 5.245 5.245z" />
                <path d="M14.423 11.85l-1.535-1.535 5.245-5.245 1.535 1.535z" />
              </g>
            </svg>
          </Link>
        </div>

        <div className="home-page__scroll">
          <button
            type="button"
            title="Scroll"
            onClick={() => {
              if (contentRef.current) {
                scrollToElement(contentRef.current);
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-down"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </button>
        </div>
      </Banner>

      <Section
        aos={true}
        theme="light"
        title={campaignPillarsSectionTitle}
        subtitle={campaignPillarsSectionSubtitle}
        link={campaignPillarsLink}
        ref={contentRef}
      >
        {campaignPillarsImageBlocks.map((imageBlock, i) => {
          const { title } = imageBlock;

          return (
            <ImageBlock
              aos={true}
              key={`${i} ${title}`}
              isReverse={i % 2 === 0}
              smallImages={true}
              {...imageBlock}
            />
          );
        })}
      </Section>

      <Section
        aos={true}
        theme="dark"
        title={campaignGoalsSectionTitle}
        subtitle={campaignGoalsSectionSubtitle}
        link={campaignGoalsLink}
      >
        <Container100 className="home-page__goals" data-aos="fade-up">
          <ImageSlider imageBlocks={campaignGoalsImageBlocks} />
        </Container100>
      </Section>

      <Banner {...bottomBanner} aos={true} />
    </Page>
  );
};

export default HomePage;

const query = graphql`
  {
    contentfulHomePage {
      topHeroBanner {
        backgroundImage {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
          file {
            url
          }
        }
        backgroundSize
        textColor
        subtitle {
          raw
        }
        title {
          raw
          references {
            ...RichTextReferences
          }
        }
      }
      campaignPillarsSectionSubtitle {
        raw
      }
      campaignPillarsSectionTitle {
        raw
      }
      campaignPillarsLink {
        ...Link
      }
      campaignPillarsImageBlocks {
        id
        image {
          fluid(maxWidth: 570) {
            aspectRatio
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
            base64
          }
        }
        title
        text {
          raw
          references {
            ...Link
          }
        }
        link {
          ...Link
        }
      }
      campaignGoalsSectionTitle {
        raw
      }
      campaignGoalsSectionSubtitle {
        raw
      }
      campaignGoalsLink {
        ...Link
      }
      campaignGoalsImageBlocks {
        id
        image {
          fluid(maxWidth: 1200) {
            aspectRatio
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
            base64
          }
        }
        title
        text {
          raw
          references {
            ...Link
          }
        }
        link {
          ...Link
        }
      }
      bottomBanner {
        backgroundImage {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
          file {
            url
          }
        }
        backgroundSize
        textColor
        subtitle {
          raw
        }
        title {
          raw
        }
        content {
          raw
        }
        link {
          ...Link
        }
      }
    }
  }
`;
